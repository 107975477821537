/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { AccentedButtonMedium } from 'components/atoms/Button';
import 'components/drawers/ThirdPartyInfoDrawer/OssContent';

import { Drawer, DrawerContext, DrawerIds } from '../../molecules/Drawer';

const testIds = {
    main: 'third-party-info-component-main',
    header: 'third-party-info-header',
    content: 'third-party-info-content',
    footer: 'third-party-info-footer',
    closeButton: 'third-party-info-close-button',
} as const;

export { testIds as thirdPartyInfoDrawerTestIds };

export const ThirdPartyInfoDrawer = () => {
    const { isOpenDrawer, closeDrawer } = React.useContext(DrawerContext);
    const { appWidth } = useAppViewPort();
    const closeHandler = () => {
        closeDrawer(DrawerIds.ThirdPartyInfoDetails);
    };

    return (
        <Drawer
            data-testid={testIds.main}
            isOpen={isOpenDrawer(DrawerIds.ThirdPartyInfoDetails)}
            onClose={closeHandler}
            width={appWidth - 32}
        >
            <div data-testid={testIds.header} className='shadow-header'>
                <div className='flex px-2 py-3 font-semibold text-text-selected_tab'>
                    OSS Readme (NPM)
                </div>
            </div>

            <div
                data-testid={testIds.content}
                className='custom-scrollbar h-[calc(100%-6rem)] overflow-auto px-2 py-1'
            >
                <oss-content />
            </div>

            <div
                data-testid={testIds.footer}
                className='flex justify-end bg-background-inverted pb-4 pr-2 pt-1'
            >
                <AccentedButtonMedium data-testid={testIds.closeButton} onClick={closeHandler}>
                    Close
                </AccentedButtonMedium>
            </div>
        </Drawer>
    );
};
