/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import type { IPluginData } from 'types/PluginData';

import { RequestDetailsDrawer } from './RequestDetailsDrawer';

export interface IRequestDetailsDrawerContext {
    resetDataContext: () => void;
    currentDataEntry?: IPluginData;
    setCurrentDataEntry: (value: IPluginData) => void;
}

export const RequestDetailsDrawerContext = React.createContext<IRequestDetailsDrawerContext>(
    {} as IRequestDetailsDrawerContext,
);

export const RequestDetailsDrawerContextProvider = ({ children }: React.PropsWithChildren) => {
    const [currentDataEntry, setCurrentDataEntry] = React.useState<IPluginData>();

    const resetDataContext = () => {
        setCurrentDataEntry(undefined);
    };

    const contextValue = React.useMemo(
        () => ({
            resetDataContext,
            currentDataEntry,
            setCurrentDataEntry,
        }),
        [currentDataEntry],
    );

    return (
        <RequestDetailsDrawerContext.Provider value={contextValue}>
            {children}
            <RequestDetailsDrawer />
        </RequestDetailsDrawerContext.Provider>
    );
};
