/******************************************************************************
 Unpublished work. Copyright 2025 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
******************************************************************************/
import ossContent from './static/oss.html?raw';

class OssContent extends HTMLElement {
    constructor() {
        super();

        this.attachShadow({ mode: 'open' });
        if (this.shadowRoot) {
            this.shadowRoot.innerHTML = ossContent;
        }
    }
}

customElements.define('oss-content', OssContent);
