/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

// workoaround for lint and formatter conflict
const shadowClass = 'shadow-button';

const BaseButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <button
            {...props}
            className={`${shadowClass} flex cursor-pointer justify-center rounded-2 px-2 py-1 text-notation font-semibold ${props.className ?? ''}`.trim()}
            type='button'
        >
            {props.children}
        </button>
    );
};

export const NormalButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <BaseButton
            {...props}
            className={`border border-button-border_base bg-button-background_base_default text-text-default_button_base ${props.className ?? ''}`.trim()}
        >
            {props.children}
        </BaseButton>
    );
};

export const AccentedButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <BaseButton
            {...props}
            className={`border-button-border_hight_contrast border border-button-border_high_contrast bg-button-background_high_contrast_default text-text-default_button_high_contrast ${props.className ?? ''}`.trim()}
        >
            {props.children}
        </BaseButton>
    );
};

export const OkButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <AccentedButton
            {...props}
            className={`w-[100px] justify-center ${props.className ?? ''}`.trim()}
        >
            {props.children}
        </AccentedButton>
    );
};

const BaseButtonMedium: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <button
            {...props}
            className={`${shadowClass} text-notation-medium flex cursor-pointer justify-center rounded-2 px-3 py-1 font-semibold ${props.className ?? ''}`.trim()}
            type='button'
        >
            {props.children}
        </button>
    );
};

export const AccentedButtonMedium: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <BaseButtonMedium
            {...props}
            className={`border-button-border_hight_contrast border border-button-border_high_contrast bg-button-background_high_contrast_default text-text-default_button_high_contrast ${props.className ?? ''}`.trim()}
        >
            {props.children}
        </BaseButtonMedium>
    );
};
