/******************************************************************************
 Unpublished work. Copyright 2024 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
 ******************************************************************************/
import type { RegionNameType } from 'types';

interface StartSessionResponse {
    sessionToken: string;
    region: RegionNameType;
}

class StartSessionError extends Error {
    statusCode: number;

    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}

const startSession = async (apiRootUrl: string, accessToken: string) => {
    const response = await fetch(`${apiRootUrl}/api/v1/session/start`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new StartSessionError('Failed to initialize session', response.status);
    }

    const data = (await response.json()) as StartSessionResponse;
    return {
        ...data,
        statusCode: response.status,
    };
};

interface KeepAliveSessionResponse {
    sessionToken: string;
}

class KeepAliveSessionError extends Error {
    statusCode: number;

    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}

const refreshSession = async (apiRootUrl: string, accessToken: string, sessionToken: string) => {
    const response = await fetch(`${apiRootUrl}/api/v1/session/refresh`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Session-Token': sessionToken,
        },
    });

    if (!response.ok) {
        throw new KeepAliveSessionError('Failed to refresh session', response.status);
    }

    const data = (await response.json()) as KeepAliveSessionResponse;
    return {
        ...data,
        statusCode: response.status,
    };
};

class EndSessionError extends Error {
    statusCode: number;

    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}

const endSession = async (apiRootUrl: string, sessionToken: string) => {
    const response = await fetch(`${apiRootUrl}/api/v1/session/end`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Session-Token': sessionToken,
        },
    });

    if (!response.ok) {
        throw new EndSessionError('Failed to end session', response.status);
    }
};

export const sessionUtil = {
    startSession,
    refreshSession,
    endSession,
    StartSessionError,
    KeepAliveSessionError,
    EndSessionError,
};
