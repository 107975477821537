/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppViewPort } from 'webComponent/hooks';

import { useEcadRequestMutation } from 'api/ecadRequestService';
import { ErrorIcon, Info24Icon, InformationSuccess24Icon } from 'assets';
import { AccentedButton, NormalButton, OkButton } from 'components/atoms/Button';
import { CloseButton } from 'components/atoms/CloseButton';
import { DisabledInput, NormalInput } from 'components/atoms/Input';
import { Label } from 'components/atoms/Label';
import { Message } from 'components/atoms/Message';
import { Drawer, DrawerContext, DrawerIds } from 'components/molecules/Drawer';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { ComponentVerticalInfo } from 'components/organisms/ComponentVerticalInfo';
import type { EcadResponseType, IEcadContentResponse } from 'types/Ecad';

import { RequestDetailsDrawerContext } from './RequestDetailsDrawerContextProvider';

const DEBUG = false;

const testIds = {
    main: 'request-details-drawer-main',
    content: 'request-details-drawer-content',
    title: 'request-details-drawer-title',
    header: 'request-details-drawer-header',
    cancelButton: 'request-details-drawer-cancel-button',
    closeButton: 'request-details-drawer-close-button',
    sendRequestButton: 'request-details-drawer-send-request-button',
    mfrPartNumber: 'request-details-drawer-mfr-part-number',
    mfrName: 'request-details-drawer-mfr-name',
    formFields: 'request-details-form-fields',
    numberOfTerminalsSection: 'request-details-drawer-number-of-terminals-section',
    numberOfTerminalsField: 'request-details-drawer-number-of-terminals-field',
    numberOfTerminalsValidationLabel: 'request-details-drawer-number-of-terminals-validation-label',
    linkToDatasheetSection: 'request-details-drawer-link-to-datasheet-section',
    linkToDatasheetField: 'request-details-drawer-link-to-datasheet-field',
    actionToolbar: 'request-details-drawer-action-toolbar',
    responseForm: 'request-details-response-form',
    errorMessage: 'request-details-error-message',
    successIcon: 'request-details-success-icon',
    infoIcon: 'request-details-info-icon',
} as const;

export { testIds as RequestDetailsDrawerTestIds };

interface IRequestDetailsProps {
    closeHandler: () => void;
}

export interface IRequestDetailsFormInputs {
    numberOfTerminals: number;
}

const HeaderDetails: React.FC<IRequestDetailsProps> = (props) => {
    const { currentDataEntry } = React.useContext(RequestDetailsDrawerContext);

    return (
        <div className='flex items-center justify-between border-b border-b-gray-300 p-1'>
            <div data-testid={testIds.title}>
                {currentDataEntry && (
                    <ComponentVerticalInfo
                        data={currentDataEntry}
                        hideRequestButton
                        hideDescription
                        hideSource
                    />
                )}
            </div>
            <CloseButton
                data-testid={testIds.closeButton}
                tab-index='-1'
                onClick={props.closeHandler}
            />
        </div>
    );
};

const ResponseIcons: React.FC<{ responseType: EcadResponseType; className: string }> = (props) => {
    if (!props) {
        return <ErrorIcon />;
    }
    switch (props.responseType) {
        case 'Info':
            return <Info24Icon data-testid={testIds.infoIcon} className={props.className} />;
        case 'Success':
            return (
                <InformationSuccess24Icon
                    data-testid={testIds.successIcon}
                    className={props.className}
                />
            );
        default:
            return <Info24Icon data-testid={testIds.infoIcon} className={props.className} />;
    }
};

const ResponseDetails: React.FC<IRequestDetailsProps & { data: IEcadContentResponse }> = (
    props,
) => {
    if (!props.data) {
        return <></>;
    }

    return (
        <div data-testid={testIds.responseForm} className='h-full'>
            <HeaderDetails closeHandler={props.closeHandler} />
            <div
                className='flex h-[calc(100vh_-_6.75rem)] flex-col justify-center px-2 pt-4 text-center'
                data-testid={testIds.content}
            >
                <ResponseIcons responseType={props.data.responseType} className='mx-auto mb-2' />
                <Message className='mb-1 text-[16px] font-semibold'>Request Data Creation</Message>
                <Message className='text-[14px] font-normal'>{props.data.message}</Message>
            </div>
            <footer
                data-testid={testIds.actionToolbar}
                className='grid flex-shrink-0 grid-flow-col justify-end gap-x-2 p-2'
            >
                <OkButton onClick={props.closeHandler}>Ok</OkButton>
            </footer>
        </div>
    );
};

const RequestDetails: React.FC<IRequestDetailsProps> = (props) => {
    const { appHeight } = useAppViewPort();
    const { currentDataEntry } = React.useContext(RequestDetailsDrawerContext);
    DEBUG && console.debug('RequestDetails', { currentDataEntry });

    const mutation = useEcadRequestMutation(currentDataEntry?.mfrPartNumber);

    const {
        register,
        handleSubmit,
        watch,
        setFocus,
        formState: { errors, isValid },
    } = useForm<IRequestDetailsFormInputs>({
        defaultValues: {
            numberOfTerminals: 1,
        },
        mode: 'all',
        criteriaMode: 'all',
    });

    React.useEffect(() => {
        setFocus('numberOfTerminals');
    }, [setFocus]);

    const isLoaded = !!currentDataEntry;

    if (!isLoaded) {
        return (
            <div className='divide-y divide-solid border-b border-b-gray-300'>
                <div className='flex'>
                    <div className='ml-auto p-2 pl-4'>
                        <CloseButton
                            data-testid={testIds.closeButton}
                            onClick={props.closeHandler}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if (mutation.isSuccess) {
        return <ResponseDetails data={mutation.data} closeHandler={props.closeHandler} />;
    }

    if (mutation.isError) {
        return (
            <div className='h-full'>
                <HeaderDetails closeHandler={props.closeHandler} />
                <ErrorMessage data-testid={testIds.errorMessage} className='h-[calc(100%-40px)]' />
            </div>
        );
    }

    const formData = watch();
    const requestHandler = () => {
        DEBUG &&
            console.debug('RequestDetails:requestHandler', {
                currentDataEntry,
            });

        if (!currentDataEntry) {
            return;
        }

        if (!isValid) {
            DEBUG &&
                console.debug('Invalid form data', { errors: errors.numberOfTerminals, formData });
            setFocus('numberOfTerminals');
            return;
        }

        if (mutation.isLoading) {
            return;
        }

        const postData = {
            manufacturer: currentDataEntry.manufacturer.name,
            mfrPartNumber: currentDataEntry.mfrPartNumber,
            terminalCount: formData.numberOfTerminals,
            datasheet: currentDataEntry.datasheet,
            supplierPartNumber: currentDataEntry.supplierPartNumber,
        };

        mutation.mutate(postData);
    };

    return (
        <div className='h-full text-notation text-text-default'>
            <HeaderDetails closeHandler={props.closeHandler} />

            <div data-testid={testIds.content} className='px-2 pt-4'>
                <header
                    data-testid={testIds.header}
                    className='bg-table-header px-4 py-2 font-semibold'
                >
                    Informations mandatory for eCAD Data request
                </header>

                <form
                    className='flex flex-col justify-between'
                    style={{ height: appHeight - 110 }}
                    onSubmit={handleSubmit((data) => {
                        DEBUG && console.debug('Form data', { data });
                    })}
                >
                    <div data-testid={testIds.formFields} className='grid gap-0.5'>
                        <div data-testid={testIds.numberOfTerminalsSection}>
                            <Label isMandatory className='py-1'>
                                Number of terminals in this part&nbsp;{' '}
                                <span data-testid={testIds.numberOfTerminalsValidationLabel}>
                                    <FormErrorMessage
                                        errors={errors}
                                        name='numberOfTerminals'
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </span>
                            </Label>
                            <NormalInput
                                {...register('numberOfTerminals', {
                                    required: 'is required.',
                                    pattern: {
                                        value: /^(0|[1-9]\d*)?$/,
                                        message: 'integer only.',
                                    },
                                    maxLength: {
                                        value: 5,
                                        message: 'exceeds maxLength.',
                                    },
                                    min: {
                                        value: 1,
                                        message: 'only numbers above zero are allowed',
                                    },
                                    max: {
                                        value: 999999,
                                        message: 'only numbers below milion are allowed',
                                    },
                                })}
                                data-testid={testIds.numberOfTerminalsField}
                                defaultValue={1}
                                placeholder='Number of terminals in this part'
                            />

                            <div className='mt-1 italic text-text-hint_text'>
                                Only numbers above zero are allowed
                            </div>
                        </div>

                        <div data-testid={testIds.linkToDatasheetSection}>
                            <Label className='py-1'>Link to datasheet</Label>
                            <DisabledInput
                                data-testid={testIds.linkToDatasheetField}
                                value={currentDataEntry.datasheet}
                            />
                        </div>
                    </div>

                    <footer
                        data-testid={testIds.actionToolbar}
                        className='grid flex-shrink-0 grid-flow-col justify-end gap-x-2 p-2'
                    >
                        <NormalButton
                            data-testid={testIds.cancelButton}
                            onClick={props.closeHandler}
                        >
                            Cancel
                        </NormalButton>
                        <AccentedButton
                            data-testid={testIds.sendRequestButton}
                            onClick={requestHandler}
                        >
                            Send Request
                        </AccentedButton>
                    </footer>
                </form>
            </div>
        </div>
    );
};

export const RequestDetailsDrawer: React.FC = () => {
    const { isOpenDrawer, closeDrawer } = React.useContext(DrawerContext);
    const { resetDataContext } = React.useContext(RequestDetailsDrawerContext);
    const closeCurrentDrawerHandler = () => {
        resetDataContext();
        closeDrawer(DrawerIds.RequestDetails);
    };

    return (
        <Drawer
            data-testid={testIds.main}
            isOpen={isOpenDrawer(DrawerIds.RequestDetails)}
            onClose={closeCurrentDrawerHandler}
            width={447}
        >
            <RequestDetails closeHandler={closeCurrentDrawerHandler} />
        </Drawer>
    );
};
