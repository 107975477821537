/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';

import { OnClickExpandIcon } from 'assets';
import { DownloadButton } from 'components/atoms/DownloadButton';
import { SupplyChainDrawerContext } from 'components/drawers/SupplyChainDrawer';
import { DragButton } from 'components/molecules/DragButton';
import { DrawerContext, DrawerIds } from 'components/molecules/Drawer';
import { EcadAvailabilityInfo } from 'components/molecules/EcadAvailabilityInfo';
import { SourceAndDescriptionTooltip } from 'components/molecules/SourceAndDescriptionTooltip';
import { SourceElement } from 'components/molecules/SourceElement';
import type { IPluginData } from 'types/PluginData';

import { SCREEN_BREAKPOINTS, type ScreenBreakpointType } from '../../../../tailwind.config';

const DEBUG = false;
const columnHelper = createColumnHelper<IPluginData>();

export const SearchTableFieldIds = {
    DataAvailability: 'data-availability-field',
    Datasource: 'datasource-field',
    Description: 'description-field',
    Drag: 'drag-field',
    MfrName: 'mfrName-field',
    MfrPartNumber: 'mfr-part-number-field',
    PdfDownload: 'pdf-download-field',
    SupplyChain: 'supply-chain-field',
} as const;

export const SearchTableColumnIds = {
    Datasource: 'datasource-column',
    Description: 'description-column',
    Drag: 'drag-field-column',
    ECADData: 'ecad-data-column',
    MPN: 'mpn-column',
    MPNLight: 'mpn-light-column',
    PdfDownload: 'pdf-download-column',
    SupplyChain: 'supply-chain-column',
} as const;
export type SearchTableColumnsType =
    (typeof SearchTableColumnIds)[keyof typeof SearchTableColumnIds];

const testIds = {
    dragButton: 'search-table-drag-button',
    detailsLink: 'supply-chain-link',
} as const;

export { testIds as SearchTableTestIds };

const SupplyChainCell = ({ componentData }: { componentData: IPluginData }) => {
    const { openDrawer, isOpenDrawer } = React.useContext(DrawerContext);
    const { currentComponent, setCurrentComponent } = React.useContext(SupplyChainDrawerContext);

    const drawerId = DrawerIds.SupplyChainDetails;

    const requestClickHandler = () => {
        siemensAnalyticsService.logEvent(
            siemensAnalyticsEvents.createFindChipsEvent('Initial View'),
        );
        setCurrentComponent(componentData);
        openDrawer(drawerId);
    };

    const isExpanded = () =>
        isOpenDrawer(drawerId) && componentData.mfrPartNumber === currentComponent?.mfrPartNumber;

    return (
        <div
            className='flex cursor-pointer select-none justify-center font-semibold text-text-link underline'
            onClick={requestClickHandler}
        >
            <span data-testid={testIds.detailsLink}>Details</span>
            {
                <OnClickExpandIcon
                    className={`${isExpanded() ? 'rotate-90' : ''} h-5 w-4 fill-icon-default_button_base stroke-icon-default_button_base py-0.5`}
                />
            }
        </div>
    );
};

export const prepareColumns = (screenBreakpoint: ScreenBreakpointType) => {
    const columns = [];

    columns.push(
        columnHelper.accessor((row) => row.supplierPartNumber, {
            id: SearchTableColumnIds.Drag,
            cell: (info) => (
                <div data-testid={SearchTableFieldIds.Drag} className='grid justify-center'>
                    <DragButton data-testid={testIds.dragButton} data={info.row.original} />
                </div>
            ),
            header: () => <span>&nbsp;</span>,
            size: 40,
            minSize: undefined,
            maxSize: undefined,
        }),
    );

    if (screenBreakpoint !== SCREEN_BREAKPOINTS.LG) {
        columns.push(
            columnHelper.accessor((row) => row.mfrPartNumber, {
                id: SearchTableColumnIds.MPN,
                cell: (info) => (
                    <div className='grid grid-flow-col grid-rows-2 justify-between gap-x-2 px-2 pt-0.5'>
                        <div
                            className='w-[152px] min-w-[152px] truncate text-left font-semibold'
                            data-testid={SearchTableFieldIds.MfrPartNumber}
                        >
                            {info.getValue()}
                        </div>
                        <div
                            className='truncate whitespace-nowrap text-left text-icon-description font-normal'
                            data-testid={SearchTableFieldIds.MfrName}
                            title={info.row.original.manufacturer.name}
                        >
                            {info.row.original.manufacturer.name}
                        </div>
                        <div className='row-span-2 place-self-center'>
                            <SourceAndDescriptionTooltip
                                source={info.row.original.dataAvailability.source}
                                description={info.row.original.description}
                            />
                        </div>
                    </div>
                ),
                header: () => <span className='block w-auto cursor-pointer'>MPN</span>,
                size: undefined,
                minSize: 192,
                maxSize: undefined,
            }),
        );
    }

    if (screenBreakpoint === SCREEN_BREAKPOINTS.LG) {
        columns.push(
            columnHelper.accessor((row) => row.mfrPartNumber, {
                id: SearchTableColumnIds.MPNLight,
                cell: (info) => (
                    <div className='grid grid-flow-col grid-rows-2 justify-between gap-x-2 px-2 pt-0.5'>
                        <div
                            className='w-[174px] truncate text-left font-semibold'
                            data-testid={SearchTableFieldIds.MfrPartNumber}
                        >
                            {info.getValue()}
                        </div>
                        <div
                            className='truncate whitespace-nowrap text-left text-icon-description font-normal'
                            data-testid={SearchTableFieldIds.MfrName}
                        >
                            {info.row.original.manufacturer.name}
                        </div>
                    </div>
                ),
                header: () => <span className='block w-auto cursor-pointer'>MPN</span>,
                size: 192,
                minSize: undefined,
                maxSize: undefined,
            }),
        );
    }

    if (screenBreakpoint === SCREEN_BREAKPOINTS.LG) {
        columns.push(
            columnHelper.accessor((row) => row.dataAvailability.source, {
                id: SearchTableColumnIds.Datasource,
                cell: (info) => (
                    <div data-testid={SearchTableFieldIds.Datasource}>
                        <SourceElement source={info.getValue()} />
                    </div>
                ),
                header: () => <span>Data Source</span>,
                size: 100,
                minSize: undefined,
                maxSize: undefined,
            }),
        );
    }

    if (screenBreakpoint !== SCREEN_BREAKPOINTS.SM) {
        const minWidth = screenBreakpoint === SCREEN_BREAKPOINTS.LG ? 174 : 162;
        columns.push(
            columnHelper.accessor((row) => row.description, {
                id: SearchTableColumnIds.Description,
                cell: (info) => (
                    <div
                        className='h-10 overflow-y-hidden text-ellipsis px-2 pt-0.5 text-left'
                        data-testid={SearchTableFieldIds.Description}
                        style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            minWidth: `${minWidth}px`,
                        }}
                    >
                        {info.getValue()}
                    </div>
                ),
                header: () => <span className='block w-auto cursor-pointer'>Description</span>,
                size: undefined,
                minSize: 162,
                maxSize: undefined,
            }),
        );
    }

    columns.push(
        columnHelper.accessor((row) => row.dataAvailability, {
            id: SearchTableColumnIds.ECADData,
            cell: (info) => (
                <div
                    data-testid={SearchTableFieldIds.DataAvailability}
                    className='grid justify-center py-2'
                >
                    <EcadAvailabilityInfo componentData={info.row.original} />
                </div>
            ),
            header: () => <span>ECAD Data</span>,
            size: 92,
            minSize: undefined,
            maxSize: undefined,
        }),
    );

    columns.push(
        columnHelper.accessor((row) => row.mfrPartNumber, {
            id: SearchTableColumnIds.SupplyChain,
            cell: (info) => (
                <div data-testid={SearchTableFieldIds.SupplyChain}>
                    <SupplyChainCell componentData={info.row.original} />
                </div>
            ),
            header: () => <span className='whitespace-nowrap'>Supply Chain</span>,
            size: 92,
            minSize: undefined,
            maxSize: undefined,
        }),
    );

    columns.push(
        columnHelper.accessor((row) => row.datasheet, {
            id: SearchTableColumnIds.PdfDownload,
            cell: (info) => (
                <div data-testid={SearchTableFieldIds.PdfDownload} className='grid justify-center'>
                    <DownloadButton datasheetUrl={info.getValue()} />
                </div>
            ),
            header: () => <span>&nbsp;</span>,
            size: 40,
            minSize: undefined,
            maxSize: undefined,
        }),
    );
    DEBUG && console.debug('SearchTableColumn-prepareColumns: columns', columns);
    return columns;
};
