/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import type { HostAppNameType, RegionNameType, ThemeNameType } from 'types';

interface IPluginContextProps {
    theme: ThemeNameType;
    sessionToken?: string;
    region?: RegionNameType;
    analyticsDigitalProductExperience?: boolean;
    analyticsProductExcellenceProgram?: boolean;
    hostAppName?: HostAppNameType;
    hostAppVersion?: string;
    rootRef: React.RefObject<HTMLDivElement>;
    appTop: number;
    appLeft: number;
    appWidth: number;
    appHeight: number;
    restartPlugin: () => void;
}

export interface IPluginContext extends IPluginContextProps {}

export const PluginContext = React.createContext<IPluginContext>({} as IPluginContext);

export const PluginContextProvider = (props: React.PropsWithChildren<IPluginContextProps>) => {
    const contextValue = React.useMemo(() => ({ ...props }), [props]);
    return <PluginContext.Provider value={contextValue}>{props.children}</PluginContext.Provider>;
};
