/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { ThirdPartyInfoDrawer } from './ThirdPartyInfoDrawer';

export const ThirdPartyInfoDrawerContext = React.createContext({});

export const ThirdPartyInfoDrawerContextProvider = ({ children }: React.PropsWithChildren) => {
    return (
        <ThirdPartyInfoDrawerContext.Provider value={{}}>
            {children}
            <ThirdPartyInfoDrawer />
        </ThirdPartyInfoDrawerContext.Provider>
    );
};
