/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useMutation } from '@tanstack/react-query';
import { useApiRootUrl, usePluginSessionToken } from 'webComponent/hooks';

import type { IEcadContentRequest, IEcadContentResponse } from 'types/Ecad';

import { api } from './apiHelper';
import apiRoutes from './apiRoutes';

const DEBUG = false;

export const useEcadRequestMutation = (mfrPartNumber?: string) => {
    const apiRootUrl = useApiRootUrl();
    const sessionToken = usePluginSessionToken();

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useEcadRequestMutation');
    }

    if (sessionToken === undefined) {
        throw new Error('sessionToken is required for useEcadRequestMutation');
    }

    return useMutation({
        mutationKey: ['postCreateEcadContent', mfrPartNumber],
        mutationFn: async (postData: IEcadContentRequest) => {
            DEBUG &&
                console.debug('useEcadRequestMutation - mutation', mfrPartNumber, { postData });

            return api.postJson<IEcadContentResponse>(
                sessionToken,
                apiRootUrl,
                apiRoutes.postCreateEcadContent,
                postData,
            );
        },
        cacheTime: 0,
    });
};
