/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import * as mocks from 'mocks';
import React from 'react';
import ReactDOM from 'react-dom/client';
import useLocalStorageState from 'use-local-storage-state';
import { sessionUtil } from 'utils';
import 'webComponent/Plugin';

import { PqLogoIcon } from 'assets';
import { apiRegionMap, isDevelopment, isMockingActive } from 'const';
import { allRegions, allThemes } from 'types';
import type { RegionNameType, ThemeNameType } from 'types';

const testIds = {
    externalAppContainer: 'external-app-container',
} as const;

if (isDevelopment) {
    console.debug('Development mode');

    if (isMockingActive) {
        console.debug('Mocks enabled');
        mocks.initialize();
    }
}

export const EmbedderDevelopment = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [sessionInitStatus, setSessionInitStatus] = React.useState('');
    const [sessionInitRegion, setSessionInitRegion] = useLocalStorageState<RegionNameType>(
        'plugin-session-init-region',
        {
            defaultValue: 'ap-northeast-1',
        },
    );
    const [sessionInitAccessToken, setSessionInitAccessToken] = useLocalStorageState(
        'plugin-session-init-access-token',
        {
            defaultValue: '',
        },
    );
    const [sessionInitSessionToken, setSessionInitSessionToken] = useLocalStorageState(
        'plugin-session-init-session-token',
        {
            defaultValue: '',
        },
    );

    const [sessionToken, setSessionToken] = useLocalStorageState<string | undefined>(
        'plugin-session-token',
    );
    const [region, setRegion] = useLocalStorageState<RegionNameType | undefined>('plugin-region');
    const [theme, setTheme] = useLocalStorageState<ThemeNameType | undefined>('plugin-theme');
    const [analyticsDigitalProductExperience, setAnalyticsDigitalProductExperience] =
        useLocalStorageState<boolean | undefined>('plugin-analytics-digital-product-experience');
    const [analyticsProductExcellenceProgram, setAnalyticsProductExcellenceProgram] =
        useLocalStorageState<boolean | undefined>('plugin-analytics-product-excellence-program');

    const [appTop, setAppTop] = useLocalStorageState<number | undefined>('plugin-app-top');
    const [appLeft, setAppLeft] = useLocalStorageState<number | undefined>('plugin-app-left');
    const [appRight, setAppRight] = useLocalStorageState<number | undefined>('plugin-app-right');

    const isReady =
        sessionToken !== undefined &&
        region !== undefined &&
        analyticsDigitalProductExperience !== undefined &&
        analyticsProductExcellenceProgram !== undefined;

    React.useEffect(() => {
        if (!isReady) {
            setIsOpen(true);
        }
    }, []);

    const initNewSession = async () => {
        setSessionInitStatus('Initializing new session...');

        const rootUrl = apiRegionMap[sessionInitRegion];

        try {
            const session = await sessionUtil.startSession(rootUrl, sessionInitAccessToken);

            setSessionInitSessionToken(session.sessionToken);

            setSessionToken(session.sessionToken);
            setRegion(session.region);

            setSessionInitStatus('Initialized, session tokens and region got filled');

            setTimeout(() => setSessionInitStatus(''), 5000);
        } catch (error) {
            console.error(error);

            if (error instanceof sessionUtil.StartSessionError) {
                if (error.statusCode === 401) {
                    setSessionInitStatus('Access token is invalid');
                    return;
                }

                setSessionInitStatus('Server returned error');
                return;
            }

            setSessionInitStatus('Cannot contact backend server');
        }
    };

    const refreshExistingSession = async () => {
        setSessionInitStatus('Refreshing existing session...');

        const rootUrl = apiRegionMap[sessionInitRegion];

        try {
            const session = await sessionUtil.refreshSession(
                rootUrl,
                sessionInitAccessToken,
                sessionInitSessionToken,
            );

            setSessionInitSessionToken(session.sessionToken);

            setSessionToken(session.sessionToken);
            setSessionInitStatus('Refreshed, session tokens got filled with new values');

            setTimeout(() => setSessionInitStatus(''), 5000);
        } catch (error) {
            console.error(error);

            if (error instanceof sessionUtil.KeepAliveSessionError) {
                if (error.statusCode === 401) {
                    setSessionInitStatus('Access token or session token is invalid');
                    return;
                }

                setSessionInitStatus('Server returned error');
                return;
            }

            setSessionInitStatus('Cannot contact backend server');
        }
    };

    const endExistingSession = async () => {
        setSessionInitStatus('Ending existing session...');

        const rootUrl = apiRegionMap[sessionInitRegion];

        try {
            await sessionUtil.endSession(rootUrl, sessionInitSessionToken);

            setSessionInitStatus('Ended, session tokens have not been cleaned up, yet');

            setTimeout(() => setSessionInitStatus(''), 5000);
        } catch (error) {
            console.error(error);

            if (error instanceof sessionUtil.EndSessionError) {
                if (error.statusCode === 401) {
                    setSessionInitStatus('Session token is invalid');
                    return;
                }

                setSessionInitStatus('Server returned error');
                return;
            }

            setSessionInitStatus('Cannot contact backend server');
        }
    };

    return (
        <div
            data-testid={testIds.externalAppContainer}
            className='relative mb-0'
            style={{
                marginTop: appTop,
                marginLeft: appLeft,
                marginRight: appRight,
            }}
        >
            <partquest-plugin
                session-token={sessionToken}
                region={region}
                theme={theme}
                analytics-digital-product-experience={analyticsDigitalProductExperience}
                analytics-product-excellence-program={analyticsProductExcellenceProgram}
                className='z-0'
            />
            <div>
                <PqLogoIcon
                    className={`fixed bottom-0 z-[99999] ${isDevelopment ? 'left-14' : ''} m-3 cursor-pointer`}
                    width={40}
                    height={40}
                    onClick={() => setIsOpen(!isOpen)}
                />
                {isOpen && (
                    <div
                        className={`fixed bottom-16 z-[99999] ${isDevelopment ? 'left-14' : ''} m-2 w-72 rounded-8 border-2 border-solid border-gray-400`}
                    >
                        <section className='rounded-t-8 bg-amber-100 p-3'>
                            <div className='flex'>
                                <h4 className='text-h4 font-bold'>Obtain session token</h4>
                                <div
                                    className='ml-auto cursor-pointer px-1'
                                    onClick={() => setIsOpen(false)}
                                >
                                    [X]
                                </div>
                            </div>

                            <p className='text-notation'>
                                Provide access token and region to initialize new session.
                            </p>
                            <p className='text-notation'>
                                To refresh existing session additionally provide current session
                                token.
                            </p>
                            <p className='text-notation'>
                                To end existing session provide current session token.
                            </p>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>Access token:</label>
                                <input
                                    type='text'
                                    className='block w-full text-notation'
                                    value={sessionInitAccessToken}
                                    onChange={(e) =>
                                        setSessionInitAccessToken(e.target.value.replace(/\s/g, ''))
                                    }
                                />
                            </div>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>Session token:</label>
                                <input
                                    type='text'
                                    className='block w-full text-notation'
                                    value={sessionInitSessionToken}
                                    onChange={(e) => setSessionInitSessionToken(e.target.value)}
                                />
                            </div>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>Region:</label>
                                <select
                                    className='block w-full text-notation'
                                    value={sessionInitRegion}
                                    onChange={(e) =>
                                        setSessionInitRegion(e.target.value as RegionNameType)
                                    }
                                >
                                    {allRegions.map((r) => (
                                        <option key={r} value={r}>
                                            {r}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='mt-2 flex'>
                                <button
                                    className='w-full rounded-3 bg-amber-500 px-3 py-1 text-notation font-semibold text-white'
                                    type='button'
                                    onClick={() => initNewSession()}
                                >
                                    init
                                </button>

                                <button
                                    className='ml-2 w-full rounded-3 bg-amber-500 px-3 py-1 text-notation font-semibold text-white'
                                    type='button'
                                    onClick={() => refreshExistingSession()}
                                >
                                    refresh
                                </button>

                                <button
                                    className='ml-2 w-full rounded-3 bg-amber-500 px-3 py-1 text-notation font-semibold text-white'
                                    type='button'
                                    onClick={() => endExistingSession()}
                                >
                                    end
                                </button>
                            </div>

                            <p className='mt-1 text-notation'>{sessionInitStatus}</p>
                        </section>

                        <section className='bg-rose-100 p-3'>
                            <div className='flex'>
                                <h4 className='text-h4 font-bold'>Initialize web component</h4>
                            </div>

                            {!isReady && (
                                <p className='text-notation font-semibold text-red-600'>
                                    Session token, region and Analytics' settings need to be set.
                                    Otherwise the application will be stuck in initialization.
                                </p>
                            )}

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>Session token:</label>
                                <input
                                    type='text'
                                    className='block w-full text-notation'
                                    value={sessionToken ?? ''}
                                    onChange={(e) =>
                                        setSessionToken(
                                            e.target.value === '' ? undefined : e.target.value,
                                        )
                                    }
                                />
                            </div>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>Region:</label>
                                <select
                                    className='block w-full text-notation'
                                    value={region}
                                    onChange={(e) => {
                                        setRegion(
                                            e.target.value === '-1'
                                                ? undefined
                                                : (e.target.value as RegionNameType),
                                        );
                                    }}
                                >
                                    <option value={-1}>---</option>
                                    {allRegions.map((r) => (
                                        <option key={r} value={r}>
                                            {r}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>
                                    Theme (siemens-horizon-light if not provided):
                                </label>
                                <select
                                    className='block w-full text-notation'
                                    value={theme}
                                    onChange={(e) =>
                                        setTheme(
                                            e.target.value === '-1'
                                                ? undefined
                                                : (e.target.value as ThemeNameType),
                                        )
                                    }
                                >
                                    <option value={-1}>---</option>
                                    {allThemes.map((t) => (
                                        <option key={t} value={t}>
                                            {t}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>
                                    Analytics (Digital Product Exp.):
                                </label>
                                <select
                                    className='block w-full text-notation'
                                    value={
                                        analyticsDigitalProductExperience === undefined
                                            ? -1
                                            : analyticsDigitalProductExperience.toString()
                                    }
                                    onChange={(e) => {
                                        setAnalyticsDigitalProductExperience(
                                            e.target.value === '-1'
                                                ? undefined
                                                : e.target.value === 'true',
                                        );
                                    }}
                                >
                                    <option value={-1}>---</option>
                                    <option value='true'>Opt in</option>
                                    <option value='false'>Opt out</option>
                                </select>
                            </div>

                            <div className='mt-2 text-notation'>
                                <label className='block font-semibold'>
                                    Analytics (Product Excellence Program):
                                </label>
                                <select
                                    className='block w-full text-notation'
                                    value={
                                        analyticsProductExcellenceProgram === undefined
                                            ? -1
                                            : analyticsProductExcellenceProgram.toString()
                                    }
                                    onChange={(e) => {
                                        setAnalyticsProductExcellenceProgram(
                                            e.target.value === '-1'
                                                ? undefined
                                                : e.target.value === 'true',
                                        );
                                    }}
                                >
                                    <option value={-1}>---</option>
                                    <option value='true'>Opt in</option>
                                    <option value='false'>Opt out</option>
                                </select>
                            </div>
                        </section>

                        <section className='rounded-b-8 bg-sky-100 p-3'>
                            <h4 className='text-h4 font-bold'>Positioning</h4>
                            <div className='mt-2 text-notation'>
                                <label htmlFor='app-top' className='block font-semibold'>
                                    App Top:
                                </label>
                                <input
                                    id='app-top'
                                    type='number'
                                    className='block w-full text-notation'
                                    value={appTop ?? ''}
                                    onChange={(e) => {
                                        const input = e.target.value.replace(/\s/g, '');
                                        const value = Number.parseInt(input, 10);
                                        setAppTop(value);
                                    }}
                                />
                            </div>

                            <div className='mt-2 text-notation'>
                                <label htmlFor='app-left' className='block font-semibold'>
                                    App Left:
                                </label>
                                <input
                                    id='app-left'
                                    type='number'
                                    className='block w-full text-notation'
                                    value={appLeft ?? ''}
                                    onChange={(e) => {
                                        const input = e.target.value.replace(/\s/g, '');
                                        const value = Number.parseInt(input, 10);
                                        setAppLeft(value);
                                    }}
                                />
                            </div>
                            <div className='mt-2 text-notation'>
                                <label htmlFor='app-right' className='block font-semibold'>
                                    App Right:
                                </label>
                                <input
                                    id='app-right'
                                    type='number'
                                    className='block w-full text-notation'
                                    value={appRight ?? ''}
                                    onChange={(e) => {
                                        const input = e.target.value.replace(/\s/g, '');
                                        const value = Number.parseInt(input, 10);
                                        setAppRight(value);
                                    }}
                                />
                            </div>
                        </section>
                    </div>
                )}
            </div>
        </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <EmbedderDevelopment />
    </React.StrictMode>,
);
