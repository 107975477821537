/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

export const DrawerIds = {
    RequestDetails: 'RequestDetails',
    FindChipsDetails: 'FindChipsDetails',
    SupplyChainDetails: 'SupplyChainDetails',
    ThirdPartyInfoDetails: 'ThirdPartyInfoDetails',
} as const;
export type DrawerIdType = (typeof DrawerIds)[keyof typeof DrawerIds];

export interface IDrawerContext {
    isOpenDrawer: (drawerId: DrawerIdType) => boolean;
    openDrawer: (drawerId: DrawerIdType) => void;
    closeDrawer: (drawerId: DrawerIdType) => void;
}

export const DrawerContext = React.createContext<IDrawerContext>({
    isOpenDrawer: () => false,
    openDrawer: () => {},
    closeDrawer: () => {},
});

export interface IDrawerConfig {
    initiallyOpen?: DrawerIdType[];
}

export const DrawerContextProvider: React.FC<React.PropsWithChildren<IDrawerConfig>> = ({
    children,
    initiallyOpen,
}) => {
    const [isRequestDetailsOpen, setIsRequestDetailsOpen] = React.useState(
        initiallyOpen?.includes(DrawerIds.RequestDetails),
    );

    const [isFindChipDetailsOpen, setIsFindChipDetailsOpen] = React.useState(
        initiallyOpen?.includes(DrawerIds.FindChipsDetails),
    );

    const [isSupplyChainDetailsOpen, setIsSupplyChainDetailsOpen] = React.useState(
        initiallyOpen?.includes(DrawerIds.SupplyChainDetails),
    );

    const [isThirdPartyInfoDetailsOpen, setIsThirdPartyInfoDetailsOpen] = React.useState(
        initiallyOpen?.includes(DrawerIds.ThirdPartyInfoDetails),
    );

    const closeDrawer = (drawerId: DrawerIdType) => {
        switch (drawerId) {
            case DrawerIds.RequestDetails:
                setIsRequestDetailsOpen(false);
                break;
            case DrawerIds.FindChipsDetails:
                setIsFindChipDetailsOpen(false);
                break;
            case DrawerIds.SupplyChainDetails:
                setIsSupplyChainDetailsOpen(false);
                break;
            case DrawerIds.ThirdPartyInfoDetails:
                setIsThirdPartyInfoDetailsOpen(false);
                break;
            default:
                throw Error(`Drawer Id ${drawerId} is not supported for closing.`);
        }
    };

    const openDrawer = (drawerId: DrawerIdType) => {
        switch (drawerId) {
            case DrawerIds.RequestDetails:
                setIsRequestDetailsOpen(true);
                break;
            case DrawerIds.FindChipsDetails:
                setIsFindChipDetailsOpen(true);
                break;
            case DrawerIds.SupplyChainDetails:
                setIsSupplyChainDetailsOpen(true);
                break;
            case DrawerIds.ThirdPartyInfoDetails:
                setIsThirdPartyInfoDetailsOpen(true);
                break;
            default:
                throw Error(`Drawer Id ${drawerId} is not supported for opening.`);
        }
    };

    const isOpenDrawer = (drawerId: DrawerIdType) => {
        switch (drawerId) {
            case DrawerIds.RequestDetails:
                return isRequestDetailsOpen ?? false;
            case DrawerIds.FindChipsDetails:
                return isFindChipDetailsOpen ?? false;
            case DrawerIds.SupplyChainDetails:
                return isSupplyChainDetailsOpen ?? false;
            case DrawerIds.ThirdPartyInfoDetails:
                return isThirdPartyInfoDetailsOpen ?? false;
            default:
                throw Error(`Drawer Id ${drawerId} is not supported for reading.`);
        }
    };

    const memoizedFunctions = React.useMemo(
        () => ({
            isOpenDrawer,
            openDrawer,
            closeDrawer,
        }),
        [
            isRequestDetailsOpen,
            isFindChipDetailsOpen,
            isSupplyChainDetailsOpen,
            isThirdPartyInfoDetailsOpen,
        ],
    );

    return <DrawerContext.Provider value={memoizedFunctions}>{children}</DrawerContext.Provider>;
};
